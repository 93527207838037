import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IFasonTakipLayout_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IFasonTakipLayout_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  GirisYapanKullanici: any[];
  GirisYapanKullanici_dummy: any[];
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  isComp923683Visible: "visible" | "hidden";
}

export class FasonTakipLayout_ScreenBase extends React.PureComponent<IFasonTakipLayout_ScreenProps, any> {
  ml = [
    {
      Id: "86c198d2-2610-49c1-b2d1-b0fad74723cd",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "8c7da2be-04a6-45c9-81b7-6c8ece778328",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 450366, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 813928, PropertyName: "value", Value: "Sisya Teknoloji" },
        { Id: 645231, PropertyName: "label", Value: "İşlemler" },
        { Id: 857512, PropertyName: "label", Value: "Ana Sayfa" },
        { Id: 923683, PropertyName: "label", Value: "Ayarlar" },
        { Id: 409394, PropertyName: "label", Value: "Tedarikçi Hesap Tanım" },
        { Id: 872113, PropertyName: "label", Value: "Ayarlar" },
        { Id: 887250, PropertyName: "label", Value: "Profil" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      IfThenElse: false,
      GirisYapanKullanici: [],
      Logout: false,
      NAVIGATE: "",
      goToSignInActivity: "",
      isComp923683Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("fasontakiplayout", "");
      return;
    }

    if (true) {
      await this.FasonTakipLayoutPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("fasontakiplayout", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("fasontakiplayout", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.FasonTakipLayoutPageInit();
    }

    if (
      prevProps.isMasterPageInitLoaded !== this.props.isMasterPageInitLoaded &&
      this.props.isMasterPageInitLoaded === true
    ) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      fasontakiplayout_156419_value: { collapsed: false }
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  FasonTakipLayoutPageInit_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "FasonTakipLayout",
      "Login",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    this.props.setMasterPageInitLoaded(true);
    return isErrorOccurred;
  };

  FasonTakipLayoutPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEmpty(ReactSystemFunctions.getMyUsername(), null)) {
      isErrorOccurred = await this.FasonTakipLayoutPageInit_if0();
      if (isErrorOccurred) return true;
    } else {
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FasonTakipLayoutPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  FasonTakipLayoutPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      CariKodu_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FasonTakipLayout/FasonTakipLayoutPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GirisYapanKullanici = result?.data.girisYapanKullanici;
    formVars.fasontakiplayout_425078_value = ReactSystemFunctions.toString(
      this,
      stateVars.GirisYapanKullanici?.length > 0 ? stateVars.GirisYapanKullanici[0]?.carI_ISIM : null
    );
    stateVars.isComp923683Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GirisYapanKullanici?.length > 0 ? stateVars.GirisYapanKullanici[0]?.tip : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FasonTakipLayoutPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FasonTakipLayoutPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.fasontakiplayout_425078_value = ReactSystemFunctions.toString(
      this,
      this.state.GirisYapanKullanici?.length > 0 ? this.state.GirisYapanKullanici[0]?.carI_ISIM : null
    );

    stateVars.isComp923683Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GirisYapanKullanici?.length > 0 ? this.state.GirisYapanKullanici[0]?.tip : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.props.setMasterPageInitLoaded(true);
    return isErrorOccurred;
  };

  FasonTakipLayoutComponent_450366_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.FasonTakipLayoutComponent_450366_onClick1_,
      "FasonTakipLayout",
      "Login",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  FasonTakipLayoutComponent_450366_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FasonTakipLayoutComponent_857512_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "FasonTakipLayout",
      "HomePage",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FasonTakipLayoutComponent_409394_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "FasonTakipLayout",
      "Tedarikcitanimlama",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FasonTakipLayoutComponent_872113_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "FasonTakipLayout",
      "Ayarlar",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FasonTakipLayoutComponent_887250_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "FasonTakipLayout",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "814618",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, , , , , 814618] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.FasonTakipLayoutPageInit();
    }
  }
}
