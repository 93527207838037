import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHomePage_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHomePage_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  AktifSirket_N: any[];
  AktifSirket_N_dummy: any[];
  GirisYapanKullanici: any[];
  GirisYapanKullanici_dummy: any[];
  GetFasoncuyaGonderilenliste: any[];
  GetFasoncuyaGonderilenliste_dummy: any[];
  GetYeniCount: number;
  GetYeniCount_dummy: number;
  GetBekleyenCount: number;
  GetBekleyenCount_dummy: number;
  GetTamamlananCount: number;
  GetTamamlananCount_dummy: number;
  GetRedFireCount: number;
  GetRedFireCount_dummy: number;
  SetValueOf: any;
  SetValueOf_dummy: any;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  ExportExcel: any;
  ExportExcel_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp67680Visible: "visible" | "hidden";
  isComp751003Visible: "visible" | "hidden";
  isComp78527Visible: "visible" | "hidden";
  isComp877597Visible: "visible" | "hidden";
  isComp559003Visible: "visible" | "hidden";
  isComp691547Visible: "visible" | "hidden";
  isComp486104Visible: "visible" | "hidden";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class HomePage_ScreenBase extends React.PureComponent<IHomePage_ScreenProps, any> {
  homepage_25957_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "86c198d2-2610-49c1-b2d1-b0fad74723cd",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "8f2efbb3-3de5-4e5b-9d13-f81465d45b4e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625866, PropertyName: "value", Value: "Yeni Gelen İşler" },
        { Id: 378348, PropertyName: "value", Value: "Bekleyen İşlerim" },
        { Id: 90595, PropertyName: "value", Value: "Tamamlanan İşlerim" },
        { Id: 758461, PropertyName: "value", Value: "Red/Fire" },
        { Id: 25957, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 67680, PropertyName: "title", Value: "Cari İsim" },
        { Id: 266829, PropertyName: "value", Value: "[datafield:cari_isim]" },
        { Id: 485369, PropertyName: "title", Value: "Tarih" },
        { Id: 14036, PropertyName: "value", Value: "[datafield:tarih]" },
        { Id: 556385, PropertyName: "title", Value: "Sevk İş Emri No" },
        { Id: 538760, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 204768, PropertyName: "value", Value: "[datafield:isemrino]" },
        { Id: 949097, PropertyName: "title", Value: "Stok Kodu" },
        { Id: 706301, PropertyName: "value", Value: "[datafield:urun_kodu]" },
        { Id: 396587, PropertyName: "title", Value: "Stok Adı" },
        { Id: 712622, PropertyName: "value", Value: "[datafield:urun_adi]" },
        { Id: 444508, PropertyName: "title", Value: "Fatura Kodu" },
        { Id: 187504, PropertyName: "value", Value: "[datafield:kt_fason_kodu]" },
        { Id: 362519, PropertyName: "title", Value: "Fatura Tanımı" },
        { Id: 990213, PropertyName: "value", Value: "[datafield:stok_adi]" },
        { Id: 272329, PropertyName: "title", Value: "Miktar/Adet" },
        { Id: 438665, PropertyName: "value", Value: "[datafield:hesaplananmiktar]" },
        { Id: 470238, PropertyName: "title", Value: "Miktar/Kg" },
        { Id: 541313, PropertyName: "value", Value: "[datafield:agirlik]" },
        { Id: 243077, PropertyName: "title", Value: "Birim Fiyat" },
        { Id: 959065, PropertyName: "value", Value: "[datafield:birimfiyat]" },
        { Id: 682843, PropertyName: "title", Value: "Toplam Fiyat" },
        { Id: 745743, PropertyName: "value", Value: "[datafield:tutar]" },
        { Id: 451444, PropertyName: "value", Value: "[datafield:parabirimi]" },
        { Id: 224475, PropertyName: "title", Value: "Üretim İş Emri No" },
        { Id: 309995, PropertyName: "value", Value: "[datafield:uretim_isemrino]" },
        { Id: 589453, PropertyName: "title", Value: "Opr. Adı" },
        { Id: 566197, PropertyName: "value", Value: "[datafield:opisim]" },
        { Id: 728713, PropertyName: "title", Value: "Opr. Açıklama" },
        { Id: 501940, PropertyName: "value", Value: "[datafield:opaciklama]" },
        { Id: 751003, PropertyName: "title", Value: "Yapılan Miktar/Adet" },
        { Id: 350228, PropertyName: "value", Value: "[datafield:yapilan_adet]" },
        { Id: 78527, PropertyName: "title", Value: "Yapılan Miktar/Kg" },
        { Id: 54432, PropertyName: "value", Value: "[datafield:yapilan_kg]" },
        { Id: 877597, PropertyName: "title", Value: "Kalan Miktar" },
        { Id: 578803, PropertyName: "value", Value: "[datafield:kalan_adet]" },
        { Id: 559003, PropertyName: "title", Value: "Fire Miktarı" },
        { Id: 309851, PropertyName: "value", Value: "[datafield:fire]" },
        { Id: 691547, PropertyName: "value", Value: "[datafield:sat_sip_durum]" },
        { Id: 486104, PropertyName: "placeholder", Value: "Type here..." }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.homepage_25957_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      AktifSirket_N: [],
      GirisYapanKullanici: [],
      GetFasoncuyaGonderilenliste: [],
      GetYeniCount: 0,
      GetBekleyenCount: 0,
      GetTamamlananCount: 0,
      GetRedFireCount: 0,
      SetValueOf: "",
      TriggerEvent: "",
      ExportExcel: "",
      NAVIGATE: "",
      isComp67680Visible: "hidden",
      isComp751003Visible: "hidden",
      isComp78527Visible: "hidden",
      isComp877597Visible: "hidden",
      isComp559003Visible: "hidden",
      isComp691547Visible: "hidden",
      isComp486104Visible: "hidden",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("homepage", "fasontakiplayout_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.HomePagePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("homepage", "fasontakiplayout_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("homepage", "fasontakiplayout_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HomePagePageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.HomePagePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };

  HomePagePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      CariKodu_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      carikod_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      tip_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "homepage_486104_value", "value", "", "", "")
        ),
        "number"
      ),
      carikod_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      tip_3: ReactSystemFunctions.convertToTypeByName(0, "number"),
      carikod_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      tip_4: ReactSystemFunctions.convertToTypeByName(1, "number"),
      carikod_5: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      tip_5: ReactSystemFunctions.convertToTypeByName(2, "number"),
      carikod_6: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      tip_6: ReactSystemFunctions.convertToTypeByName(3, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePagePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AktifSirket_N = result?.data.aktifSirket_N;
    stateVars.GirisYapanKullanici = result?.data.girisYapanKullanici;
    stateVars.isComp67680Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GirisYapanKullanici?.length > 0 ? stateVars.GirisYapanKullanici[0]?.tip : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691547Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GirisYapanKullanici?.length > 0 ? stateVars.GirisYapanKullanici[0]?.tip : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GetFasoncuyaGonderilenliste = result?.data.getFasoncuyaGonderilenliste;

    stateVars.GetYeniCount = result?.data.getYeniCount;
    formVars.homepage_724181_value = ReactSystemFunctions.toString(this, stateVars.GetYeniCount);
    stateVars.GetBekleyenCount = result?.data.getBekleyenCount;
    formVars.homepage_462340_value = ReactSystemFunctions.toString(this, stateVars.GetBekleyenCount);
    stateVars.GetTamamlananCount = result?.data.getTamamlananCount;
    formVars.homepage_26789_value = ReactSystemFunctions.toString(this, stateVars.GetTamamlananCount);
    stateVars.GetRedFireCount = result?.data.getRedFireCount;
    formVars.homepage_868529_value = ReactSystemFunctions.toString(this, stateVars.GetRedFireCount);
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HomePagePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePagePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.homepage_724181_value = ReactSystemFunctions.toString(this, this.state.GetYeniCount);

    formVars.homepage_462340_value = ReactSystemFunctions.toString(this, this.state.GetBekleyenCount);

    formVars.homepage_26789_value = ReactSystemFunctions.toString(this, this.state.GetTamamlananCount);

    formVars.homepage_868529_value = ReactSystemFunctions.toString(this, this.state.GetRedFireCount);

    stateVars.dataSource_25957 = this.state.GetFasoncuyaGonderilenliste;
    stateVars.isComp67680Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GirisYapanKullanici?.length > 0 ? this.state.GirisYapanKullanici[0]?.tip : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp751003Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "homepage_486104_value", "value", "", "", "")
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78527Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "homepage_486104_value", "value", "", "", "")
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp877597Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "homepage_486104_value", "value", "", "", "")
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559003Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "homepage_486104_value", "value", "", "", "")
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691547Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GirisYapanKullanici?.length > 0 ? this.state.GirisYapanKullanici[0]?.tip : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HomePageComponent_837799_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePageComponent_837799_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AktifSirket_N = result?.data.aktifSirket_N;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HomePageComponent_837799_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePageComponent_837799_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "homepage_486104_value", "0", null);
    await this.HomePagePageInit();

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePageComponent_314655_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePageComponent_314655_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AktifSirket_N = result?.data.aktifSirket_N;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HomePageComponent_314655_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePageComponent_314655_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "homepage_486104_value", "1", null);
    await this.HomePagePageInit();

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePageComponent_425316_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePageComponent_425316_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AktifSirket_N = result?.data.aktifSirket_N;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HomePageComponent_425316_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePageComponent_425316_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "homepage_486104_value", "2", null);
    await this.HomePagePageInit();

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePageComponent_616780_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePageComponent_616780_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AktifSirket_N = result?.data.aktifSirket_N;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HomePageComponent_616780_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePageComponent_616780_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "homepage_486104_value", "3", null);
    await this.HomePagePageInit();

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePageComponent_111686_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      value_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetFasoncuyaGonderilenliste),
        "any"
      ),
      header_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      footer_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePageComponent_111686_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  HomePageComponent_204768_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ResimDetay",
      "Resim",
      ReactSystemFunctions.toString(
        this,
        this.state.GetFasoncuyaGonderilenliste?.length > 0 ? this.state.GetFasoncuyaGonderilenliste[0]?.resimUrl : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePage",
      "ResimDetay",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePageComponent_554809_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ResimDetay",
      "Resim",
      ReactSystemFunctions.value(this, "homepage_25957_value", "resim")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePage",
      "ResimDetay",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "906115",
      null,
      null,
      "True",
      "600px",
      "400px",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, 906115] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.HomePagePageInit();
    }
  }
}
