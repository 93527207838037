import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProfil_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProfil_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GirisYapanKullanici: any[];
  GirisYapanKullanici_dummy: any[];
  KullaniciSifreDegistir: number;
  KullaniciSifreDegistir_dummy: number;
  PasswordChange: string;
  PasswordChange_dummy: string;
  Notify: boolean;
  Notify_dummy: boolean;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  SetValueOf: any;
  SetValueOf_dummy: any;
}

export class Profil_ScreenBase extends React.PureComponent<IProfil_ScreenProps, any> {
  ml = [
    {
      Id: "86c198d2-2610-49c1-b2d1-b0fad74723cd",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "daba60cb-8749-40f1-8df8-cc8ce244a0ce",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 626432, PropertyName: "value", Value: "Mevcut Şifreniz :" },
        { Id: 669239, PropertyName: "value", Value: "Yeni Şifre" },
        { Id: 821262, PropertyName: "placeholder", Value: "Lütfen Yeni Şifrenizi Girin" },
        { Id: 515752, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GirisYapanKullanici: [],
      KullaniciSifreDegistir: 0,
      PasswordChange: "",
      Notify: false,
      TriggerEvent: "",
      SetValueOf: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("profil", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProfilPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("profil", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("profil", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProfilPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ProfilPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      CariKodu_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Profil/ProfilPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GirisYapanKullanici = result?.data.girisYapanKullanici;
    formVars.profil_926585_value = ReactSystemFunctions.toString(
      this,
      stateVars.GirisYapanKullanici?.length > 0 ? stateVars.GirisYapanKullanici[0]?.sifre : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProfilPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProfilPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.profil_926585_value = ReactSystemFunctions.toString(
      this,
      this.state.GirisYapanKullanici?.length > 0 ? this.state.GirisYapanKullanici[0]?.sifre : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProfilComponent_515752_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profil_821262_value", "value", "", "", "")
        ),
        null
      ),
      message: "Bu alan boş bırakılamaz.",
      formName: "profil_821262_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SIFRE_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "profil_821262_value", "value", "", "", "")),
        "string"
      ),
      CARI_KODU_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "879e7aa8_8439_3514_2bec_39cd44a66207_confirmation",
        this.defaultML,
        "Şifreniz Güncellensinmi ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Profil/ProfilComponent_515752_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.KullaniciSifreDegistir = result?.data.kullaniciSifreDegistir;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ProfilComponent_515752_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProfilComponent_515752_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profil_821262_value", "value", "", "", "")
        ),
        null
      ),
      message: "Bu alan boş bırakılamaz.",
      formName: "profil_821262_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.PasswordChange = await ReactSystemFunctions.changePassword(
      ReactSystemFunctions.toString(
        this,
        this.state.GirisYapanKullanici?.length > 0 ? this.state.GirisYapanKullanici[0]?.sifre : null
      ),
      ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "profil_821262_value", "value", "", "", "")),
      false,
      "",
      undefined
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "24b7221b_6c85_808d_7f5c_4ed7c5e3d0ff_notify",
        this.defaultML,
        "Şifre Değiştirilmiştir."
      ),
      "success",
      "top-center",
      3,
      "",
      "",
      0
    );
    await this.ProfilPageInit();

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "profil_821262_value", null, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
